html, body {
  font-size: 62.5%;
}

body {
  position: relative;
  height: 100vh;
  width:100vw;
  overflow: hidden;
}

p,button {
  font-size: 2rem;
}
